<template>
  <transition name="bounce">
    <div v-if="isProduction && isServerVersionGreater" class="new-version">
      <div class="new-version__message">New version is available!</div>
      <Btn type="primary" padding="xs" height="s" @click="update">Click to update</Btn>
    </div>
  </transition>
</template>

<script>
  import { mapState } from 'vuex';
  import Btn from '@/uikitBase/btns/Btn';
  import { isProduction } from 'utils/appHelpers';

  export default {
    name: 'NewVersion',

    components: { Btn },

    computed: {
      ...mapState(['isServerVersionGreater']),

      isProduction() {
        return isProduction();
      },
    },

    methods: {
      update() {
        window.location.reload();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .new-version {
    position: fixed;
    z-index: 3;
    right: 30px;
    bottom: 30px;
    display: flex;
    align-items: center;
    width: max-content;
    padding: 10px 15px;
    border-radius: $border-radius__lg;
    background-color: $color-bg-second;
    line-height: 1.5;
    font-size: $size-sm;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15), 0 3px 20px 0 rgba(0, 0, 0, 0.12);

    @media (max-width: $screen-sm-max) {
      bottom: 15px;
      left: 50%;
      transform: translateX(-50%);
      font-size: $size-xs;
    }

    &__message {
      margin-right: 25px;

      @media (max-width: $screen-sm-max) {
        margin-right: 15px;
      }
    }
  }

  .bounce-enter-active {
    animation: bounce-in 0.5s;
  }
  .bounce-leave-active {
    animation: bounce-in 0.5s reverse;
  }

  @keyframes bounce-in {
    0% {
      transform: scale(0);

      @media (max-width: $screen-sm-max) {
        transform: translateX(-50%) scale(0);
      }
    }
    50% {
      transform: scale(1.25);

      @media (max-width: $screen-sm-max) {
        transform: translateX(-50%) scale(1.25);
      }
    }
    100% {
      transform: scale(1);

      @media (max-width: $screen-sm-max) {
        transform: translateX(-50%) scale(1);
      }
    }
  }
</style>
