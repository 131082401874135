<template>
  <div class="dual" :class="classSplitView">
    <!--use main here only because of global styles, main can be only one on a page-->
    <main class="dual__side" :class="classPrimary">
      <RouterView class="dual__content dual__content--primary" />

      <!--Absolute positioned-->
      <Transition name="appear-with-delay">
        <div
          v-if="secondaryRoute"
          key="actions-primary"
          class="dual__actions dual__actions--primary"
        >
          <BtnIcon iconMaterial="close" class="dual__btn dual__btn--close" @click="closePrimary" />
          <BtnIcon
            iconMaterial="tab"
            class="dual__btn dual__btn--open"
            @click="openInNewTab('primary')"
          />
        </div>
      </Transition>
    </main>

    <div v-if="secondaryRoute" class="dual__divider"></div>

    <Transition name="expand">
      <main v-if="secondaryRoute" class="dual__side dual__side--secondary">
        <SecondaryView
          :component="secondaryComponent"
          :componentProps="secondaryComponentProps"
          class="dual__content"
        />

        <!--Absolute positioned-->
        <div key="actions-secondary" class="dual__actions dual__actions--secondary">
          <BtnIcon
            iconMaterial="tab"
            class="dual__btn dual__btn--open"
            @click="openInNewTab('secondary')"
          />
          <BtnIcon
            iconMaterial="close"
            class="dual__btn dual__btn--close"
            @click="closeSecondary"
          />
        </div>
      </main>
    </Transition>

    <!--Absolute positioned-->
    <NewVersion />
  </div>
</template>

<script>
  import BtnIcon from '@/uikitBase/btns/BtnIcon';
  import RouterHelper from 'utils/RouterHelper.ts';
  import SecondaryView, {
    isPrimaryView,
    isSecondaryView,
  } from '@/components/blocks/layouts/dual/SecondaryView';
  import NewVersion from 'components/blocks/layouts/NewVersion';

  export default {
    name: 'Dual',

    components: {
      NewVersion,
      SecondaryView,
      BtnIcon,
    },

    provide: {
      [isPrimaryView]: true,
      [isSecondaryView]: false,
    },

    computed: {
      classPrimary() {
        // Primary route can exists only with a secondary one
        return this.secondaryRoute && 'dual__side--primary';
      },
      classSplitView() {
        return this.secondaryRoute && 'dual--split';
      },

      secondaryRoute() {
        const { pathSecondary } = this.$route.query;
        if (pathSecondary) {
          return this.$router.resolve(decodeURIComponent(pathSecondary));
        }
        return null;
      },

      secondaryComponent() {
        const { pathSecondary } = this.$route.query;

        if (pathSecondary) {
          // The first matched result is a layout, skip it and use a page
          const [, component] = this.$router.getMatchedComponents(
            decodeURIComponent(pathSecondary),
          );
          return component;
        }

        throw Error('Error! Wrong secondary page!');
      },
      secondaryComponentProps() {
        if (!this.secondaryRoute) return;

        const route = this.$router
          .getRoutes()
          .find((route) => route.name === this.secondaryRoute.route.name);

        if (route?.props.default instanceof Function) {
          return route.props.default(this.secondaryRoute.route);
        }
        return {};
      },
    },

    methods: {
      closePrimary() {
        RouterHelper.closePrimary();
      },
      closeSecondary() {
        RouterHelper.closeSecondary();
      },

      openInNewTab(sideType) {
        const setAsPrimary = 'setAsPrimary=true';

        const { fullPath } =
          sideType === 'primary' ? RouterHelper.routePrimary : RouterHelper.routeSecondary;
        const hasQuery = fullPath.includes('?');
        const url = `${fullPath}${hasQuery ? '&' : '?'}${setAsPrimary}`;

        window.open(`${location.origin}${location.pathname}#${url}`, '_blank').focus();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .dual {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100vw;
    height: calc(100vh - 60px);
    overflow: hidden;

    @media print {
      height: unset;
    }

    &__side {
      position: relative;
      flex: 1;
      display: flex;
      justify-content: center;
      height: 100%;
      overflow-y: auto;
      max-width: unset;
      margin: 0;

      &.scroll-lock {
        overflow: hidden;
      }

      &--primary {
        direction: rtl;

        @media print {
          display: none;
        }
      }

      &--secondary {
        flex: none;
        width: 50vw;
        display: flex;
        justify-content: center;

        @media print {
          margin: auto;
          width: auto;
        }
      }
    }

    &__side:hover &__actions {
      opacity: 1;
    }

    &__side--secondary {
      &.appear-with-delay-leave-active,
      &.appear-with-delay-enter-active {
        .side__actions {
          display: none !important;
        }
      }
    }

    &__actions.appear-with-delay-leave-active,
    &__actions.appear-with-delay-enter-active {
      display: none !important;
    }

    &__content {
      flex: none;
      width: 750px;

      &::after {
        content: '';
        display: block;
        height: 64px;
      }

      &--primary {
        direction: ltr;
      }
    }

    &__divider {
      margin: 6px;
      width: 4px;
      align-self: stretch;
      background-color: $color-bg-third;
      border-radius: 5px;

      @media print {
        display: none;
      }
    }

    &__actions {
      position: fixed;
      display: flex;
      align-items: center;
      opacity: 0;
      top: 84px;
      transition: opacity 0.3s;

      &--primary {
        right: calc(50% + 14px);
      }

      &--secondary {
        right: 14px;
      }
    }

    &__btn {
      color: $color-text-third;

      &:hover {
        color: $color-text-primary;
      }

      &--close {
        font-size: 20px;
        margin: 0 10px;
      }

      &--open {
        font-size: 18px;
      }
    }
  }

  .expand {
    &-enter-to,
    &-leave {
      width: 50vw;
    }

    &-enter,
    &-leave-to {
      width: 0;
    }

    &-enter-active,
    &-leave-active {
      overflow: hidden;
      transition: width 0.7s ease-in-out;
    }
  }

  .appear-with-delay {
    &-enter-active,
    &-leave-active {
      transition-delay: 0.7s;
    }
  }
</style>
